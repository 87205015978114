export var ProductClientStatus;
(function (ProductClientStatus) {
    ProductClientStatus["Active"] = "active";
    ProductClientStatus["Upcoming"] = "upcoming";
    ProductClientStatus["Finished"] = "finished";
})(ProductClientStatus || (ProductClientStatus = {}));
export var ProductChargeType;
(function (ProductChargeType) {
    ProductChargeType["Paid"] = "paid";
    ProductChargeType["Free"] = "free";
})(ProductChargeType || (ProductChargeType = {}));
export var CouponCodeStatus;
(function (CouponCodeStatus) {
    CouponCodeStatus["Inactive"] = "inactive";
    CouponCodeStatus["Active"] = "active";
    CouponCodeStatus["Redeemed"] = "redeemed";
})(CouponCodeStatus || (CouponCodeStatus = {}));
export const PaymentTypeOption = {
    OneTime: 'one_time',
    Recurring: 'recurring',
    NFT: 'nft',
};
export const OrderPaymentTypeOption = {
    OneTime: 'one_time',
    Recurring: 'recurring',
    Free: 'free',
    NFT: 'nft',
    FullyDiscounted: 'full_discount',
};
export var ProductType;
(function (ProductType) {
    ProductType["Mailing"] = "mailing";
    ProductType["Course"] = "course";
    ProductType["Membership"] = "membership";
    ProductType["OneTime"] = "one_time";
    ProductType["KnowledgeBase"] = "knowledge_base";
})(ProductType || (ProductType = {}));
export var PaymentClient;
(function (PaymentClient) {
    PaymentClient["Stripe"] = "stripe";
    PaymentClient["PayU"] = "payu";
    PaymentClient["Metamask"] = "metamask";
    PaymentClient["Tpay"] = "tpay";
})(PaymentClient || (PaymentClient = {}));
