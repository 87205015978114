import { COMMUNITY_SETTINGS_REDUCER_NAME } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    data: null,
    error: null,
    requestStatus: null,
};
const communitySettingsSlice = createSlice({
    name: COMMUNITY_SETTINGS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToCommunitySettingsStarted(state) {
            state.requestStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
        },
        subscribeToCommunitySettingsSuccess(state, { payload }) {
            state.requestStatus = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToCommunitySettingsFailed(state, { payload }) {
            state.requestStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromCommunitySettings(state) {
            state.data = null;
            state.error = null;
        },
    },
});
export const { subscribeToCommunitySettingsStarted, subscribeToCommunitySettingsSuccess, subscribeToCommunitySettingsFailed, unsubscribeFromCommunitySettings, } = communitySettingsSlice.actions;
export default communitySettingsSlice.reducer;
