export const FIRESTORE_DOCUMENT = {
    INTEGRATION_CONFIG: 'integration-config',
    PRODUCTS_DATA: 'products-data',
    PRODUCT_AUTOMATION: 'product-automation',
    PRODUCT_REFERRALS: 'product-referrals',
    APP_STATS: 'app-stats',
    CREATOR_STATS: 'creator-stats',
    SUBSCRIPTIONS: 'subscriptions',
    SUBSCRIPTIONS_CONFIG: 'subscriptionsConfig',
    SHARED_KEYS: 'sharedKeys',
    PRODUCT_CONTENT_COURSE: 'product-content-course',
    PRODUCT_FILES_CONTENT: 'product-files-content',
    PRODUCT_USER_SETTINGS_PREFIX: 'user-settings-',
    COMMUNITY_SETTINGS: 'community-settings',
    COMMUNITY_MEMBER_SETTINGS: 'community-member-settings',
};
