import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloudFunctionErrorHandler } from '../../../../helpers/cloudFunctionErrorHandler';
import { INTEGRATION_REDUCER_NAME } from '../types';
export const integrateWithTpay = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/integrateWithTpay`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('integrations-integrateWithTpay')({
            clientId: payload.clientId.trim(),
            safetyKey: payload.safetyKey.trim(),
            clientSecret: payload.clientSecret.trim(),
            name: payload.name.trim(),
            posId: payload.posId.trim(),
        });
        analytics.track('integrate_with_tpay', {
            userId: user.uid,
            email: user.email,
            client: payload.clientId,
            posId: payload.posId,
            name: payload.name,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.(cloudFunctionErrorHandler(e).code);
        return rejectWithValue(e);
    }
});
