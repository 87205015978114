import React, { FunctionComponent, useEffect } from 'react';
import { Select, Spin } from 'antd';
import zanfiaHeader from '../../assets/icons/zanfia-black.png';
import bg from '../../assets/images/auth-bg.jpg';
import i18n from 'i18next';
import { useAppDispatch } from '~/initializeStore';
import { userActions } from '@mailingr/shared';

interface OwnProps {}

type Props = OwnProps;

const FrameLogOut: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      userActions.logOutUser(() => {
        window.parent.postMessage({ call: 'isLoggedOut' }, '*');
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className="flex min-h-screen bg-white">
        <div className="hidden lg:block relative w-0 flex-1">
          <img className="absolute inset-0 h-full w-full object-cover" src={bg} alt="" />
        </div>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="absolute right-4 top-4 lg:right-8 lg:top-8">
              <Select
                value={i18n.language}
                size="large"
                onChange={(lang) => i18n.changeLanguage(lang)}
                dropdownMatchSelectWidth={false}
              >
                <Select.Option value="en" key="en">
                  <span className="flag-icon flag-icon-gb" />
                  🇺🇸 EN
                </Select.Option>
                <Select.Option value="pl" key="pl">
                  <span className="flag-icon flag-icon-pl" />
                  🇵🇱 PL
                </Select.Option>
              </Select>
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="flex justify-start px-2 items-center mb-8">
                <a href="https://zanfia.com" target="_blank" rel="nofollow noreferrer">
                  <img src={zanfiaHeader} alt="Zanfia" className="h-10 w-auto mx-auto block" />
                </a>
              </div>
            </div>
            <div>
              <Spin />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrameLogOut;
