import { COLLECTION } from '../../../../firestore/collectionNames';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
import { COMMUNITY_SETTINGS_REDUCER_NAME } from '../types';
export const updateCommunitySettings = createAsyncThunk(`${COMMUNITY_SETTINGS_REDUCER_NAME}/updateCommunitySettings`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth }, dispatch, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged in');
        }
        const { data } = getState().communitySettings;
        const communityRef = db
            .collection(COLLECTION.COMMUNITIES)
            .doc(payload.communityId)
            .collection(COLLECTION.COMMUNITY_CONFIG)
            .doc(FIRESTORE_DOCUMENT.COMMUNITY_SETTINGS);
        if (!data) {
            await communityRef.set({
                ...payload.settings,
                createdAt: new Date(),
                updatedAt: new Date(),
            });
            onSuccess?.();
            return;
        }
        await communityRef.update({
            ...(data || {}),
            ...payload.settings,
            updatedAt: new Date(),
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
