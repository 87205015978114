import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchArchivedProductClients = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchArchivedProductClients`, async ({ productId, ownerId }, { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }) => {
    try {
        const { productArchivedClientsListSize } = getState().product;
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .where('status', '==', 'archived')
            .orderBy('archivedInfo.archivedAt', 'desc')
            .limit(productArchivedClientsListSize);
        const res = await ref.get();
        const list = res.docs.map((doc) => firestoreDateMapper(doc));
        if (!res.empty) {
            firestoreSubscriptions.lastArchivedProductClientCursor = res.docs[res.docs.length - 1];
        }
        return {
            list,
            isLastPage: list.length < productArchivedClientsListSize,
        };
    }
    catch (e) {
        if (e instanceof Error) {
            return rejectWithValue(e);
        }
        else {
            return rejectWithValue(new Error('Unknown Error'));
        }
    }
});
