import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchNextPageProductArchivedClients = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchNextPageProductArchivedClients`, async ({ productId, page, ownerId }, { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }) => {
    try {
        const { productArchivedClientsListSize, isLastPageForArchived: isFetchedAllItems, productArchivedPage, productArchivedClients, } = getState().product;
        if (isFetchedAllItems || (productArchivedPage && page <= productArchivedPage)) {
            return {
                page,
                list: [],
                isLastPage: isFetchedAllItems,
            };
        }
        const countPages = page - Math.ceil(productArchivedClients.length / productArchivedClientsListSize);
        if (countPages <= 0) {
            return {
                page,
                list: [],
                isLastPage: isFetchedAllItems,
            };
        }
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .where('status', '==', 'archived')
            .orderBy('archivedInfo.archivedAt', 'desc')
            .limit(productArchivedClientsListSize * (page - productArchivedPage))
            .startAfter(firestoreSubscriptions.lastArchivedProductClientCursor);
        const snapshot = await ref.get();
        const isLastPage = snapshot.docs.length < productArchivedClientsListSize;
        firestoreSubscriptions.lastArchivedProductClientCursor = isLastPage
            ? null
            : snapshot.docs[snapshot.docs.length - 1];
        const list = snapshot.docs.map((doc) => firestoreDateMapper(doc));
        const currentPage = productArchivedPage || 1;
        return {
            list,
            isLastPage,
            page: page - currentPage,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
