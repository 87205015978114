import { ConversionTrackingClient } from '../../enums/ConversionTrackingClient';
import { EmailClient } from '../../enums/EmailClient';
import { IntegrationClient } from '../../enums/IntegrationClient';
import { InvoicesClient } from '../../enums/InvoicesClient';
export var WFirmaIntegrationStatus;
(function (WFirmaIntegrationStatus) {
    WFirmaIntegrationStatus["InProgress"] = "inProgress";
    WFirmaIntegrationStatus["Completed"] = "completed";
    WFirmaIntegrationStatus["Rejected"] = "rejected";
})(WFirmaIntegrationStatus || (WFirmaIntegrationStatus = {}));
export var CheckoutTheme;
(function (CheckoutTheme) {
    CheckoutTheme["Indigo"] = "indigo";
    CheckoutTheme["Black"] = "black";
    CheckoutTheme["Pink"] = "pink";
    CheckoutTheme["Rose"] = "rose";
    CheckoutTheme["Sky"] = "sky";
    CheckoutTheme["Emerald"] = "emerald";
    CheckoutTheme["Blue"] = "blue";
    CheckoutTheme["Teal"] = "teal";
    CheckoutTheme["Violet"] = "violet";
    CheckoutTheme["Purple"] = "purple";
    CheckoutTheme["Fuchsia"] = "fuchsia";
    CheckoutTheme["Cyan"] = "cyan";
    CheckoutTheme["Gray"] = "gray";
    CheckoutTheme["Yellow"] = "yellow";
    CheckoutTheme["YellowLight"] = "yellow-light";
})(CheckoutTheme || (CheckoutTheme = {}));
