import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const removeContentModule = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/removeContentModule`, async ({ productId, moduleId }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    if (!data?.modules) {
        throw new Error('invalid-modules');
    }
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);
    const batch = db.batch();
    batch.update(ref, {
        updatedAt: new Date(),
        modules: data.modules.filter((m) => m.id !== moduleId),
    });
    const module = data.modules.find((module) => module.id === moduleId);
    const lessons = module ? module.lessons.map((lesson) => lesson.id) : [];
    lessons.forEach((lessonId) => {
        batch.delete(ref.collection(COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonId));
    });
    await batch.commit();
    analytics.track('remove_content_module', { ownerId: user.uid, productId });
});
