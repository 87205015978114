import { createSelector } from '@reduxjs/toolkit';
const getData = (store) => store.communityMembers.data;
const getSearchTerm = (store) => store.communityMembers.searchTerm;
export const getFilteredCommunityMembers = createSelector([getData, getSearchTerm], (members, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
        return members;
    }
    const searchInLowerCase = searchTerm.toLowerCase();
    return members.filter((member) => {
        return (member.firstName.toLowerCase().includes(searchInLowerCase) ||
            member.lastName?.toLowerCase().includes(searchInLowerCase) ||
            member.email.toLowerCase().includes(searchInLowerCase));
    });
});
