import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentClient } from '../../../../enums/PaymentClient';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { PRODUCT_REDUCER_NAME } from '../types';
const defaultPriceConfig = {
    showAsPromo: false,
    activeUntil: null,
    enableQuantityBuy: false,
};
export const updateProductPriceConfig = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/updateProductPriceConfig`, async (payload, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        const { product } = getState();
        if (product.details?.id !== payload.productId) {
            throw new Error('different-product-id');
        }
        if (!product.details.prices || !product.details.prices.length) {
            throw new Error('product-without-prices');
        }
        const updatedPrices = product.details.prices.map((price) => price.id === payload.priceId
            ? {
                ...price,
                config: { ...defaultPriceConfig, ...payload.config },
                ...(price.paymentClient === PaymentClient.PayU && payload.config.enableQuantityBuy
                    ? { quantity: price.quantity ? price.quantity : price.clients }
                    : null),
            }
            : price);
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId);
        await ref.update({
            prices: updatedPrices,
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
