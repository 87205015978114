export var CommunityRole;
(function (CommunityRole) {
    CommunityRole["Owner"] = "owner";
    // Admin = 'admin',
    // Moderator = 'moderator',
    CommunityRole["Member"] = "member";
    // Viewer = 'viewer',
    // Commenter = 'commenter',
    // Banned = 'banned',
})(CommunityRole || (CommunityRole = {}));
export var ChannelType;
(function (ChannelType) {
    ChannelType["Feed"] = "feed";
    ChannelType["Chat"] = "chat";
    ChannelType["Events"] = "events";
    ChannelType["Link"] = "link";
})(ChannelType || (ChannelType = {}));
export var PermissionLevel;
(function (PermissionLevel) {
    PermissionLevel["Unavailable"] = "unavailable";
    PermissionLevel["Read"] = "read";
    PermissionLevel["Comment"] = "comment";
    PermissionLevel["Write"] = "write";
    PermissionLevel["Admin"] = "admin";
})(PermissionLevel || (PermissionLevel = {}));
export var SortingType;
(function (SortingType) {
    SortingType["Latest"] = "latest";
    SortingType["Oldest"] = "oldest";
    SortingType["TopActivity"] = "topActivity";
})(SortingType || (SortingType = {}));
export const parseCommunityMemberId = (id) => id;
export const parseCommunityOwnerId = (id) => id;
export const parseCommunityId = (id) => id;
export const parseGroupId = (id) => id;
export const parseChannelId = (id) => id;
export const parseChannelPostId = (id) => id;
export const parseChannelPostCommentId = (id) => id;
export var MembersListVariant;
(function (MembersListVariant) {
    MembersListVariant["Grid"] = "grid";
    MembersListVariant["Horizontal"] = "horizontal";
})(MembersListVariant || (MembersListVariant = {}));
export var ChannelStatus;
(function (ChannelStatus) {
    ChannelStatus["Active"] = "active";
    ChannelStatus["Hidden"] = "hidden";
    ChannelStatus["Archived"] = "archived";
    ChannelStatus["Deleted"] = "deleted";
})(ChannelStatus || (ChannelStatus = {}));
export var ChannelPostStatus;
(function (ChannelPostStatus) {
    ChannelPostStatus["Draft"] = "draft";
    ChannelPostStatus["Published"] = "published";
    ChannelPostStatus["Archived"] = "archived";
    ChannelPostStatus["Deleted"] = "deleted";
    ChannelPostStatus["Hidden"] = "hidden";
})(ChannelPostStatus || (ChannelPostStatus = {}));
export var ChannelCommentStatus;
(function (ChannelCommentStatus) {
    ChannelCommentStatus["Published"] = "published";
    ChannelCommentStatus["Deleted"] = "deleted";
    ChannelCommentStatus["Hidden"] = "hidden";
})(ChannelCommentStatus || (ChannelCommentStatus = {}));
